<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p class="title">Issuance History</p>
      </div>
    </div>
    <div v-if="false" class="columns is-vcentered">
      <div class="column is-3">
        <b-field>
          <b-input
            placeholder="Search..."
            type="search"
            v-model="searchQuery"
            @input="filterItems"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field>
          <div class="columns is-vcentered">
            <div class="column is-narrow bold">Search by:</div>
            <div class="column">
              <b-radio
                class="mt-1 pl-2"
                v-for="(columnData, index) in tableProps.columnData"
                :key="index"
                v-model="searchType"
                name="searchType"
                :native-value="columnData"
                @input="filterItems"
              >
                {{ tableProps.columnTitle[index] }}
              </b-radio>
            </div>
          </div>
        </b-field>
      </div>
      <div class="column is-narrow">
        <b-field label="Go to page:">
          <b-input v-model="tableProps.page" type="number" min="1"></b-input>
        </b-field>
      </div>
    </div>
    <b-input
      class="mt-4"
      placeholder="Search Book by Accession No..."
      type="search"
      v-model="searchQuery"
      @input="filterItems"
    ></b-input>
    <hr />
    <b-table
      :data="filteredIssuances"
      :loading="tableProps.isLoading"
      :striped="tableProps.isStriped"
      :total="tableProps.total"
      :per-page="tableProps.perPage"
      :current-page="parseInt(tableProps.page)"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      paginated
    >
      <b-table-column label="Accession No" sortable v-slot="props">
        {{ props.row.printedBook.accessionNo }}
      </b-table-column>

      <b-table-column label="Title" sortable v-slot="props">
        {{ props.row.printedBook.title }}
      </b-table-column>

      <b-table-column label="Author" sortable v-slot="props">
        {{
          Array.isArray(props.row.printedBook.authors)
            ? props.row.printedBook.authors.join(", ")
            : props.row.printedBook.authors | filter
        }}
      </b-table-column>

      <b-table-column label="Issue Date" sortable v-slot="props">
        {{ props.row.issueDate | filter }}
      </b-table-column>

      <b-table-column label="Due Date" sortable v-slot="props">
        {{ props.row.dueDate | filter }}
      </b-table-column>

      <b-table-column label="Issued To" sortable v-slot="props">
        {{ props.row.user.firstName + " " + props.row.user.lastName }}
      </b-table-column>

      <b-table-column sortable label="Member ID" v-slot="props">
        {{ props.row.user.memberId }}
      </b-table-column>

      <b-table-column label="Overdue Days" sortable v-slot="props">
        {{ props.row.dueDate | daysDuration(props.row.returnDate) }}
      </b-table-column>

      <b-table-column label="Return Date" sortable v-slot="props">
        {{ props.row.returnDate | filter }}
      </b-table-column>
    </b-table>
    <!-- Modals Start -->
    <!-- Modals End -->
  </div>
</template>

<script>
import { orderBy, filter } from "lodash";

export default {
  name: "Viewer",
  data() {
    return {
      filteredItems: undefined,
      searchQuery: "",
      searchType: "memberId",
      returnDate: new Date(),
      tableProps: {
        columnTitle: [
          "Accession No",
          "Title",
          "Author",
          "Issue Date",
          "Due Date",
          "Issued To",
          "Overdue Days",
          "Return Date"
        ],
        columnData: [
          "printedBook.accessionNo",
          "printedBook.title",
          "printedBook.authors",
          "issueDate",
          "dueDate",
          "user.memberId",
          "overdueDays",
          "returnDate"
        ],
        total: 0,
        isLoading: false,
        isStriped: true,
        sortOrder: "desc",
        defaultSortOrder: "desc",
        page: 1,
        perPage: 15
      },
      modals: {
        showViewDetailModal: false,
        showDeleteItemModal: false,
        viewModalTitles: [
          "Accession No",
          "Title",
          "Author",
          "Issue Date",
          "Due Date",
          "Issued To",
          "Overdue Days",
          "Return Date"
        ],
        viewModalTitlesData: [
          "accessionNumber",
          "title",
          "author",
          "issueDate",
          "dueDate",
          "memberId",
          "overdueDays",
          "returnDate"
        ]
      },
      itemDetail: undefined,
      filteredIssuances: undefined
    };
  },
  async created() {
    this.tableProps.isLoading = true;
    await this.$store.dispatch("getIssuances", "history");
    this.filteredItems = this.items;
    this.tableProps.isLoading = false;
  },
  computed: {
    items() {
      return orderBy(this.$store.state.issuedBooks, ["dateCreated"], ["desc"]);
    }
  },
  watch: {
    items: function(val) {
      this.filteredItems = val;
      this.filterItems();
    }
  },
  methods: {
    filterItems() {
      if (this.items) {
        if (this.searchQuery === "") {
          this.filteredIssuances = this.items;
        } else {
          this.filteredIssuances = filter(this.items, item => {
            return item.printedBook["accessionNo"]
              .toString()
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase());
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
